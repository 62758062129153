@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './assets/scss/vaiables';

* {
  margin: 0;
  top: 0;
  box-sizing: border-box;
  outline: none;
  box-shadow: none;
  list-style: none;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

.p-inputtext {
  box-shadow: none !important;

  &:enabled:focus {
    box-shadow: none !important;
  }
}

.p-dropdown {
  &.p-focus {
    box-shadow: none !important;
  }

  &:hover {
    box-shadow: none !important;
  }
}

.p-button-primary {
  background-color: #7854f7 !important;

  &.w-full {
    display: flex;
    justify-content: center;
  }
}

.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
